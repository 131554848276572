<template>
    <div>
        <breadcrumb
            name="A+"
            title="Advance Plus"
            subtitle="Add"
            @onTitleClicked="backToList"
        />
        <div class="body">
            <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
            <div>
                <div style="float: right">
                    <h6 class="header">Show On Mobile</h6>
                    <vs-switch v-model="mobile_active" />
                </div>
                <div class="clr"></div>
            </div>
            <div>
                <div class="english">
                    <vs-input
                        label="Name English*"
                        v-model="name_en"
                        size="medium"
                        class="input"
                        :danger="flags.name_en"
                        danger-text="This field is mandatory"
                    />
                    <small class="label">Description English*</small>
                    <ckeditor
                        :editor="editor"
                        v-model="description_en"
                        :config="editorConfigEn"
                        class="input"
                        v-if="!showHTML"
                    />
                    <textarea
                        v-model="description_en"
                        v-if="showHTML"
                    ></textarea>
                    <small v-if="flags.description_en" style="color: red"
                        >This field is mandatory</small
                    >
                    <small v-if="flags.lengthExceeded_en" style="color: red"
                        >Text should't be more than 3000 characters</small
                    >
                </div>
                <div class="arabic">
                    <vs-input
                        label="Name Arabic*"
                        v-model="name_ar"
                        size="medium"
                        class="input"
                        :danger="flags.name_ar"
                        danger-text="This field is mandatory"
                    />
                    <small class="label">Description Arabic*</small>
                    <ckeditor
                        :editor="editor"
                        v-model="description_ar"
                        :config="editorConfigAr"
                        class="input"
                        v-if="!showHTML"
                    />
                    <textarea
                        v-model="description_ar"
                        v-if="showHTML"
                    ></textarea>
                    <small v-if="flags.description_ar" style="color: red"
                        >This field is mandatory</small
                    >
                    <small v-if="flags.lengthExceeded_ar" style="color: red"
                        >Text should't be more than 3000 characters</small
                    >
                </div>
            </div>
            <div class="optionBtns">
                <vs-button
                    :disabled="invalidForm || invalidFields"
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="resetData"
                    >Cancel
                </vs-button>
                <vs-button
                    class="ml-5"
                    type="border"
                    @click="showHTML = !showHTML"
                    >{{ showHTML ? "Hide HTML" : "Show HTML" }}</vs-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";

export default {
    name: "add-aplus",
    components: { Breadcrumb, HomeIcon, ChevronsRightIcon },
    props: {},
    data() {
        return {
            showHTML: false,
            name_ar: "",
            description_ar: "",
            name_en: "",
            description_en: "",
            mobile_active: true,
            editor: ClassicEditor,
            editorConfigAr: {
                placeholder: "تفاصيل",
                language: "ar"
            },
            editorConfigEn: {
                placeholder: "Details",
                language: "en"
            },
            flags: {
                name_ar: false,
                name_en: false,
                description_ar: false,
                description_en: false,
                lengthExceeded_ar: false,
                lengthExceeded_en: false
            },
            form: false
        };
    },
    methods: {
        backToList() {
            this.$emit("cancel");
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to save these data!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm"
            });
        },
        resetData() {
            this.backToList();
        },
        saveData() {
            this.$vs.loading();
            const data = {
                name_en: this.name_en,
                name_ar: this.name_ar,
                description_en: this.description_en,
                description_ar: this.description_ar,
                mobile_active: this.mobile_active,
            }

            this.$http
                .post(`/advance_plus`, data)
                .then(() => {
                    this.$emit("saved");
                    this.$emit("cancel");
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Saved",
                        text: "Data is Saved Successfully",
                        color: "primary"
                    });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger"
                    });
                });
        }
    },
    computed: {
        invalidForm() {
            if (
                this.flags.name_ar ||
                this.flags.name_en ||
                this.flags.description_ar ||
                this.flags.description_en ||
                this.flags.lengthExceeded_ar ||
                this.flags.lengthExceeded_en
            ) {
                return true;
            } else {
                return false;
            }
        },
        invalidFields() {
            if (
                this.name_ar &&
                this.name_en &&
                this.description_ar &&
                this.description_en
            ) {
                return false;
            } else {
                return true;
            }
        }
    },
    watch: {
        name_ar(v) {
            v === ""
                ? (this.flags.name_ar = true)
                : (this.flags.name_ar = false);
        },
        name_en(v) {
            v === ""
                ? (this.flags.name_en = true)
                : (this.flags.name_en = false);
        },
        description_ar(v) {
            v.replace(/(<([^>]+)>)/gi, "") === ""
                ? (this.flags.description_ar = true)
                : (this.flags.description_ar = false);
            v.replace(/(<([^>]+)>)/gi, "").length > 3000
                ? (this.flags.lengthExceeded_ar = true)
                : (this.flags.lenghtExceeded_ar = false);
        },
        description_en(v) {
            v.replace(/(<([^>]+)>)/gi, "") === ""
                ? (this.flags.description_en = true)
                : (this.flags.description_en = false);
            v.replace(/(<([^>]+)>)/gi, "").length > 3000
                ? (this.flags.lengthExceeded_en = true)
                : (this.flags.lengthExceeded_en = false);
        }
    },
    mounted() {
        this.flags.form = true;
    }
};
</script>

<style scoped lang="scss">
@import "../../basic-styles/mixins";

%flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
}

.images {
    @include margin-y(30px);
    text-align: center;
}

.optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
        position: relative;
        left: 25px;
    }
}

.input {
    width: 100%;
    @include margin-y(30px);
}

.arabic,
.english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
}

h1 {
    margin-bottom: 25px;
}

.inputs {
    @extend %flex;
}

ul {
    li {
        display: inline;
    }
}

.clr {
    clear: both;
}

$primaryColor: #054f7c;

.breadcrumb {
    margin: 20px;
}

.back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
}

.main-component {
    color: $primaryColor;
}

.required {
    color: red;
    font-size: 1.5rem;
}

.mandatory {
    color: red;
}

.arabic,
.english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px;
}

.custom-class {
    color: $primaryColor;
}

.title {
    font-size: 2rem;
    font-weight: 400;
}

.separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
}

.label {
    color: #00000099;
    padding-left: 5px;
}

.settings {
    margin: 30px 0 30px 30px;

    .header {
        margin-bottom: 10px;
    }
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.centered {
    text-align: center;
}

textarea {
    width: 100%;
    height: 350px;
}
</style>
